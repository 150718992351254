'use strict';
var customUtil = require('../customUtil');
var calculateCountdown = require('../utils/countdown');
var searchMenuEl = $('.header-search-type');
var bodyEl = $('body');
var showMenuClass = 'open';
var noScrollClass = 'no-scroll';
var removeMobileClass = false;
var timer = null;
var pageScrollingClass = 'page-scrolling';

/** Update Z-index **/

var updateZindex = function (update) {
    if (update) {
        $('.sticky-header').addClass('updateZindex');
    } else {
        $('.sticky-header').removeClass('updateZindex');
    }
};

/**
 * @param {Element} element js element
 * @returns {boolean} if element is in view
 */
function isInViewport(element) {
    var elementTop = $(element).offset().top;
    var elementBottom = elementTop + $(element).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
}

/** Toggle search dropdown*/
function toggleSearchMenu() {
    var openingMenu = !$(searchMenuEl).hasClass('showMenuClass');
    var searchField = $('.search-field');
    var isMobileMenuOpen = $('.header-container')
        .find('.main-menu')
        .hasClass('in');
    // closing mobile menu when click on search
    if (isMobileMenuOpen) {
        $('.header-container')
            .find('.main-menu')
            .find('.close-button')
            .trigger('click');
        $('.navbar-toggler')
            .removeClass('open')
            .addClass('close');
    }
    $(searchMenuEl).toggleClass(showMenuClass);
    var ariaValue = $(searchMenuEl).attr('aria-expanded') !== 'true';
    updateZindex(ariaValue);
    $(searchMenuEl).attr('aria-expanded', ariaValue);
    var timeDelay = openingMenu ? 0 : 500;

    setTimeout(function () {
        $(bodyEl).toggleClass(noScrollClass);
    }, timeDelay);

    if (openingMenu) {
        setTimeout(function () {
            $(searchField).focus();
        }, timeDelay);
        $('.search-menu')
            .find('.reset-button')
            .trigger('click');
    }
}

/** Close search dropdown*/

var closeSearchMenu = function (e) {
    var target = e.target;
    if (target.matches('.header-search-type .fade-bg')) {
        $(searchMenuEl).removeClass(showMenuClass);
        $('.suggestions').hide();
        $(searchMenuEl).attr('aria-expanded', false);
        updateZindex(false);
        setTimeout(function () {
            $(bodyEl).removeClass(noScrollClass);
        }, 400);
    }
};

/** Showing helper message, and hiding search clear*/

var showHelperMessage = function (e) {
    if (e.target.value.trim().length < 3 && e.target.value.trim().length !== 0) {
        $('.search-menu-container')
            .find('.search-helper-msg')
            .removeClass('d-none');
        if ($('.search-menu-container').find('.recent-search').length) {
            $('.search-menu-container')
                .find('.recent-search')
                .hide();
        }
    } else {
        $('.search-menu-container')
            .find('.search-helper-msg')
            .addClass('d-none');
        if ($('.search-menu-container').find('.recent-search').length) {
            $('.search-menu-container')
                .find('.recent-search')
                .show();
        }
    }
    if (e.target.value.trim().length > 0) {
        $('.search-menu-container')
            .find('.reset-button')
            .removeClass('d-none');
    } else {
        $('.search-menu-container')
            .find('.reset-button')
            .addClass('d-none');
    }
    if (e.target.value.trim().length >= 3) {
        $('.suggestions-wrapper-default').addClass('d-none');
    } else {
        $('.suggestions-wrapper-default').removeClass('d-none');
    }
};

/** Hide search popup*/
function closeSearchPopup() {
    $('.search-menu').removeClass('open');
    $('.search-menu')
        .find('.reset-button')
        .trigger('click');
    $('.suggestions-wrapper-default').removeClass('d-none');
    $(bodyEl).removeClass(noScrollClass);
}

/** Clear search popup*/
function clearSearchPopup() {
    $(this).addClass('d-none');
    $('.suggestions-wrapper-default').removeClass('d-none');
    $('.suggestions').html('');
    $('.search-menu-container')
        .find('.search-helper-msg')
        .addClass('d-none');
    $('.search-field').focus();
    if ($('.search-menu-container').find('.recent-search').length) {
        $('.search-menu-container')
            .find('.recent-search')
            .show();
    }
}

/** Change top of mobile menu, when header banner close */
function setMobileMenuTop() {
    var bannerHeight = $('.js-page.fixed-header').innerHeight();

    $('.main-menu').css('top', bannerHeight);
}

/** Remove scroll class to body*/
function closeMenu() {
    $(bodyEl).removeClass(noScrollClass);
    $('.main-menu').removeClass('in');
    $('.modal-background').hide();
    $('.navbar .nav-menu')
        .find('.close-button')
        .trigger('click');
}

/** Change mega menu sub dropdown class*/
function changeMegaMenuClass() {
    if ($(window).width() >= 1024) {
        $('.desktop-menu')
            .addClass('dropdown-menu')
            .removeClass('d-none');
        $('.mobile-menu')
            .removeClass('dropdown-menu')
            .addClass('d-none');
        $('.main-menu').removeClass('in');
        $('.modal-background').hide();
        $(bodyEl).removeClass(noScrollClass);
        // Adding max height to search suggestions for custom scroll
        // 40px gap to show fade background below to search container
        var searchBarHeight = $(window).height() - $('.header-banner').innerHeight() - $('.navbar-header').innerHeight() - 40;
        $('.search-menu-container').css('max-height', searchBarHeight);
    } else {
        $('.desktop-menu')
            .removeClass('dropdown-menu')
            .addClass('d-none');
        $('.mobile-menu')
            .addClass('dropdown-menu')
            .removeClass('d-none');
        setMobileMenuTop();
    }
    if (!removeMobileClass) {
        removeMobileClass = true;
        $('.menu-group .navbar-nav .nav-item').each(function () {
            if ($(this).find('.mobile-menu-container').length) {
                $(this).append(
                    $(this)
                        .find('.mobile-menu-container')
                        .children()
                );
                $(this)
                    .find('.mobile-menu-container')
                    .remove();
            }
        });
    }
}

/** Set search bar top*/
var setSearchBarTop = function () {
    if ($(window).width() >= 1024) {
        setTimeout(function () {
            $('.search-menu').css('top', $('.navbar-header').innerHeight());
        }, 300);
    } else {
        setTimeout(function () {
            $('.search-menu').css('top', 0);
        }, 300);
    }
};

var setFilterBarTop = function () {
    if (customUtil.isMobile()) {
        var $filterBar = $('.filter-bar');
        var $stickyHeader = $('.sticky-header');

        if ($filterBar.length > 0) {
            if ($(window).scrollTop() >= $filterBar.offset().top - $stickyHeader.height()) {
                $filterBar.addClass('fixed-header').css('top', $stickyHeader.height());
            } else {
                $filterBar.removeClass('fixed-header').removeAttr('style');
            }
        }
    }
};

/** Window resize */
function onResize() {
    setSearchBarTop();
    setFilterBarTop();
    changeMegaMenuClass();

    /* check if Desktop View */
    if (!customUtil.isMobile()) {
        /* removed the fixed position in Desktop */
        var elementArray = ['sticky-header', 'filter-bar', 'category-search'];

        elementArray.forEach(function (el) {
            $('.' + el).removeClass('fixed-header');
        });

        $('body').removeClass(pageScrollingClass);
    }
}

/** Fix header on scroll*/
var stickyHeader = function () {
    var scrollTop = $(window).scrollTop();
    var header = $('.sticky-header');
    var headerBanner = $('.header-banner');
    var headerBannerNegativeHeight = headerBanner.innerHeight() * -1;
    if (scrollTop > 0) {
        header.addClass('bg-white');
        if (scrollTop > $('.fixed-header').innerHeight()) {
            header.css('margin-top', headerBannerNegativeHeight);
            headerBanner.css('top', headerBannerNegativeHeight);
        } else {
            header.css('margin-top', 0);
            headerBanner.css('top', 'auto');
        }
        $('body').css('paddingTop', $('.sticky-header').innerHeight());
        setMobileMenuTop();
        $('.back-to-top').fadeIn();
    } else {
        header.removeClass('bg-white').css('margin-top', 0);
        headerBanner.css('top', 'auto');
        $('body').css('paddingTop', 0);
        $('.back-to-top').fadeOut();
    }
    setSearchBarTop();
    setFilterBarTop();
};

/** Show fade background on nav mouseover*/
function showFadeBg() {
    if ($(window).width() >= 1024) {
        if ($(this).find('.sub-menu').length) {
            $('.main-menu')
                .find('.fade-bg')
                .show();
            updateZindex(true);
        } else {
            $('.main-menu')
                .find('.fade-bg')
                .hide();
            if (!$('.search-menu').hasClass('open')) {
                updateZindex(false);
            }
        }
    }
}

/** Hide fade background on nav mouseout*/
function hideFadeBg() {
    $('.main-menu')
        .find('.fade-bg')
        .hide();
    if (!$('.search-menu').hasClass('open')) {
        updateZindex(false);
    }
}

/**
 * Select category in mobile menu
 * @param {Element} $dropdown - dropdown element
 */
function selectMenuCategory($dropdown) {
    if ($dropdown && $dropdown.children()) {
        var navLink = $dropdown.children()[0];
        if ($(navLink).hasClass('dropdown-toggle')) {
            $dropdown.addClass('show');
            var $li = $('<li class="dropdown-item top-category" role="button"></li>');
            var $link = $(navLink)
                .clone()
                .removeClass('dropdown-toggle')
                .removeAttr('data-toggle')
                .removeAttr('aria-expanded')
                .attr('aria-haspopup', 'false');
            $li.append($link);
            var $closeMenu = $('<li class="nav-menu"></li>');
            $closeMenu.append(
                $('.close-menu')
                    .first()
                    .clone()
            );
            $(navLink)
                .parent()
                .children('.dropdown-menu')
                .prepend($li)
                .prepend($closeMenu)
                .attr('aria-hidden', 'false');
            $(navLink).attr('aria-expanded', 'true');
            $link.focus();
            $('div.menu-group > ul.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
        }
    }
}

/** Open mobile menu*/
function openMobileMenu() {
    $('.main-menu').toggleClass('in');
    $('.modal-background').show();

    $('.main-menu').removeClass('d-none');
    $('.main-menu').attr('aria-hidden', 'false');
    $('.main-menu')
        .siblings()
        .attr('aria-hidden', 'true');
    $('header')
        .siblings()
        .attr('aria-hidden', 'true');

    $('.main-menu .nav.navbar-nav .nav-link')
        .first()
        .focus();

    if ($(this).hasClass('close')) {
        $(this)
            .removeClass('close')
            .addClass('open');
        $(bodyEl).addClass(noScrollClass);
        setMobileMenuTop();
        updateZindex(true);
        $('.modal-background').addClass('modal-zindex-high');
    } else {
        $(this)
            .removeClass('open')
            .addClass('close');
        closeMenu();
        $('.bfx-cc-expanded').hide();
        updateZindex(false);
        $('.modal-background').removeClass('modal-zindex-high');
    }

    if ($('.category-search').length > 0 && $('.main-menu').hasClass('in')) {
        var categoryID = $('.category-search.update-count-header').attr('data-category-id');

        if (categoryID) {
            var $categoryLink = $('#' + categoryID + '.nav-row-link');
            if ($categoryLink.length > 0) {
                if ($categoryLink.parent().hasClass('dropdown-item') || $categoryLink.parent().hasClass('nav-item')) {
                    var $allDropDowns = $categoryLink.parents('.dropdown');
                    if ($allDropDowns !== null && $allDropDowns !== undefined) {
                        $($allDropDowns).each(function () {
                            selectMenuCategory($(this));
                        });
                    }
                }
            }
        }
    }
}

/** Navigation for touch screens*/

var showMegaMenu = function (e) {
    if ($(window).width() >= 1024 && !navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
        var ele = $(this)
            .closest('.nav-item')
            .find('.desktop-menu');
        var isMenuOpen = $(ele).css('display') === 'block';
        if (!isMenuOpen && $(ele).length) {
            e.preventDefault();
            $('.desktop-menu').hide();
            $(this)
                .closest('.nav-item')
                .find('.desktop-menu')
                .show();
            $(this)
                .closest('.navbar')
                .find('.fade-bg')
                .show();
            $('.navbar-nav')
                .find('.nav-item .nav-item-l1-link')
                .removeClass('active');
            $(this).addClass('active');
        }
    }
};

/** Reset megamenu*/
function resetMegaMenu() {
    $('.desktop-menu').hide();
    $('.navbar')
        .find('.fade-bg')
        .hide();
    $('.navbar-nav')
        .find('.nav-item .nav-item-l1-link')
        .removeClass('active');
}

/** Hide navigation on click of fade background*/
function hideMegaMenuDropdown() {
    if (
        $(window).width() >= 1024 &&
        $('.navbar-nav')
            .find('.nav-item .nav-item-l1-link')
            .hasClass('active')
    ) {
        resetMegaMenu();
    }
}

/** Hide navigation on click of fade background*/

var hideMegaMenu = function (e) {
    if ($(window).width() >= 1024) {
        var container = $('.main-menu');
        if (!container.is(e.target) && (container.has(e.target).length === 0 || $(this).hasClass('fade-bg'))) {
            resetMegaMenu();
        }
    }
};

/** Hide containers when click on outside*/

var hideContainersOnMouseClickOut = function (e) {
    // Hide Search container when click on outside
    if ($('.search-menu').has(e.target).length && $('.search-menu').hasClass('open') && !$('.reset-button').has(e.target).length) {
        $('.suggestions').show();
    } else if (!$('.search-toggle').has(e.target).length && !$('.search-menu').has(e.target).length && $('.search-menu').hasClass('open')) {
        $('.search-menu').removeClass('open');
        $(searchMenuEl).attr('aria-expanded', false);
        updateZindex(false);
        $(bodyEl).removeClass(noScrollClass);
    }

    // Hide country container when click on outside
    if (!$('.navbar-header .country-selector').has(e.target).length) {
        $('.navbar-header .country-selector')
            .find('.bfx-cc-expanded')
            .hide();
    }
    if (
        !$('.menu-group')
            .find('.country-container')
            .has(e.target).length
    ) {
        $('.menu-group')
            .find('.country-container')
            .find('.bfx-cc-expanded')
            .hide();
    }

    // Hide megamenu container when click on outside
    hideMegaMenu(e);
};

/** Hide dropdown when focus*/
function hideMegaMenuOnFocus() {
    if ($(window).width() >= 1024) {
        $('.desktop-menu')
            .hide()
            .removeClass('show');
    }
}
/** Hide mobile menu when click on grey background*/
function hideMobileMenu() {
    if ($('.main-menu').hasClass('in')) {
        $('.header-container')
            .find('.main-menu')
            .find('.close-button')
            .trigger('click');
        $('.header .navbar-toggler')
            .removeClass('open')
            .addClass('close');
    }
}

/**
 * initialize the vertical carousel in the header banner
 */
function initVerticalCarousel() {
    $('.header-carousel-slider').on('init', function () {
        var $headerBanner = $('.header-banner');
        if (isInViewport($headerBanner.get(0))) {
            $('.storepage, .main-content *:not(script):first').css('padding-top', $('.js-page').innerHeight() + 'px');
        } else {
            $('.storepage, .main-content *:not(script):first').css('padding-top', $('.js-page').innerHeight() + $headerBanner.innerHeight() + 'px');
        }
    });

    $('.header-carousel-slider').slick({
        vertical: true,
        autoplay: true,
        arrows: false,
        dots: false,
        autoplaySpeed: 5000,
        speed: 500
    });

    $('.header-carousel-slider').on('beforeChange', function() {
        var $headerBanner = $('.header-banner');
        if (isInViewport($headerBanner.get(0))) {
            $('.storepage, .main-content *:not(script):first').css('padding-top', $('.js-page').innerHeight() + 'px');
        } else {
            $('.storepage, .main-content *:not(script):first').css('padding-top', $('.js-page').innerHeight() + $headerBanner.innerHeight() + 'px');
        }
    });

    var maxHeight = -1;
    var $slickSlide = $('.header-carousel-slider .slick-slide');

    /* search and get the max height of the slick slides */
    $slickSlide.each(function () {
        if ($(this).height() > maxHeight) {
            maxHeight = $(this).height();
        }
    });

    /* calculate the highest slide and set a top/bottom margin for other children. */
    $slickSlide.each(function () {
        if ($(this).height() < maxHeight) {
            $(this).css('margin', Math.ceil((maxHeight - $(this).height()) / 2) + 'px 0');
        }
    });

    $('.header-banner').css('opacity', 1);
}

/**
 * Set SFMCU user info
 * @param {string} email - email
 */
function setSFMCUserInfo(email) {
    if (email) {
        customUtil.setCookie('sfmcUserInfo', email, 90);

        if (typeof _etmc !== 'undefined') {
            _etmc.push(['setUserInfo', { email: email }]); // eslint-disable-line no-undef
        }
    }
}

/**
 * Adds padding to first element of main content if promo carousel is unavailable
 */
function addFirstChildPadding() {
    var $headerBanner = $('.header-banner');
    if (isInViewport($headerBanner.get(0))) {
        $('.storepage, .main-content *:not(script):first').css('padding-top', $('.js-page').innerHeight() + 'px');
    } else {
        $('.storepage, .main-content *:not(script):first').css('padding-top', $('.js-page').innerHeight() + $headerBanner.innerHeight() + 'px');
    }
    $('.header-banner').css('opacity', 1);
}

/** Document on Ready*/
function onReady() {
    changeMegaMenuClass();

    if ($('.header-carousel-slider').length > 0) {
        setTimeout(initVerticalCarousel, 500);
    } else {
        setTimeout(addFirstChildPadding, 500);
    }

    if (!customUtil.isMobile()) {
        $('body').removeClass(pageScrollingClass);
    }

    $('body').attr('data-scroll-position', 0);

    if (customUtil.isSafari()) {
        $('body').addClass('safari-only');
    }

    // this event will trigger once attentive popup submitted
    window.addEventListener('sendAttentive', function (ev) {
        if (ev.detail && ev.detail.email) {
            setSFMCUserInfo(ev.detail.email);
        }
    });
}

/** set the scroll position as new attribute of the body element */
function setScrollPosition() {
    var top = $('.header-container').innerHeight();
    if ($('.sticky-header').hasClass('fixed-header')) {
        $('body').attr('data-scroll-position', $(window).scrollTop() - top);
    } else {
        $('body').attr('data-scroll-position', $(window).scrollTop());
    }
}

/**
 * Set counter
 * @param {Date} dateTime - date
 */
function setCounter(dateTime) {
    var countdown = calculateCountdown(dateTime);
    if (countdown) {
        var days = countdown.dayDifference > 1 ? ' DAYS' : ' DAY';
        var hours = countdown.hoursDifference > 1 ? ' HRS' : ' HR';
        var mins = countdown.minutesDifference > 1 ? ' MINS' : ' MIN';
        var countdownTime;
        if (countdown.dayDifference === 0) {
            if (countdown.hoursDifference > 0) {
                countdownTime = countdown.hoursDifference + hours + ', ' + countdown.minutesDifference + mins;
            } else {
                countdownTime = countdown.minutesDifference + mins;
            }
        } else if (countdown.hoursDifference > 0) {
            countdownTime = countdown.dayDifference + days + ', ' + countdown.hoursDifference + hours + ', ' + countdown.minutesDifference + mins;
        } else {
            countdownTime = countdown.dayDifference + days + ', ' + countdown.minutesDifference + mins;
        }
        $('.header-banner #countdown').html(countdownTime);
    }
}

/** Wait for Element to Load on the Page */
function waitForElm(selector) {
    return new Promise(resolve => {
        if (document.querySelector(selector)) {
            return resolve(document.querySelector(selector));
        }

        const observer = new MutationObserver(mutations => {
            if (document.querySelector(selector)) {
                observer.disconnect();
                resolve(document.querySelector(selector));
            }
        });

        observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    });
}

module.exports = function () {
    var searchToggles = $('.search-toggle-btn-main');
    var searchField = $('.search-field');
    var closeButton = $('.m-search-close');
    var resetButton = $('.reset-button');

    $(window).on('load', function () {
        if ($('#onetrust-pc-btn-handler').length > 0) {
            $('#onetrust-pc-btn-handler').attr('tabindex', 3);
        }
        if ($('#onetrust-accept-btn-handler').length > 0) {
            $('#onetrust-accept-btn-handler').attr('tabindex', 2);
        }
    });

    if (closeButton) {
        $(closeButton).on('click', closeSearchPopup);
    }
    if (resetButton) {
        $(resetButton).on('click', clearSearchPopup);
    }
    if (searchField) {
        $(searchField).on('keyup', showHelperMessage);
    }
    if (searchToggles) {
        $(searchToggles).on('click', toggleSearchMenu);
    }

    if (searchMenuEl) {
        $(searchMenuEl).on('click', closeSearchMenu);
    }
    $('.modal-background').on('click', hideMobileMenu);
    $(window).on('scroll', function () {
        clearTimeout(timer);

        if (customUtil.isMobile() && $('.filter-bar').length > 0) {
            if ($(window).scrollTop() >= $('.filter-bar').offset().top - $('.sticky-header').height()) {
                $(bodyEl).addClass(pageScrollingClass);
            }

            timer = setTimeout(function () {
                $(bodyEl).removeClass(pageScrollingClass);
            }, 500);
        }

        stickyHeader();
        setScrollPosition();
    });
    $(window).on('resize', onResize);
    $('.navbar-nav')
        .find('.nav-item')
        .on('mouseenter', showFadeBg);
    $('.navbar-nav')
        .find('.nav-item')
        .on('mouseleave', hideFadeBg);
    $('.navbar-toggler')
        .unbind('click')
        .on('click', openMobileMenu);
    $(document).ready(onReady);
    $('.navbar .close-menu')
        .find('.close-button')
        .on('click', closeMenu);
    $('.header-banner')
        .find('.close-button')
        .on('click', function () {
            $('.header .minicart .popover').addClass('no-header-banner');
            setMobileMenuTop();
        });
    $('.navbar-nav')
        .find('.nav-item .nav-item-l1-link')
        .on('touchstart', showMegaMenu);
    $('.navbar-nav')
        .find('.nav-item .nav-item-l1-link')
        .on('mouseenter', hideMegaMenuDropdown);
    $('.navbar-nav')
        .find('.nav-item .nav-item-l1-link')
        .on('focus', hideMegaMenuOnFocus);
    $('.navbar')
        .find('.fade-bg')
        .on('click', hideMegaMenu);
    $(document).on('click', hideContainersOnMouseClickOut);

    $('.header-nav-icons .minicart .minicart-link').on('focus', function () {
        if ($('.header-nav-icons .country-selector').length > 0) {
            $('.header-nav-icons .country-selector')
                .removeClass('show')
                .find('.bfx-cc-expanded')
                .hide();
        }
    });

    $('.header-nav-icons .user-account-menu .user-account-title').on('focus', function () {
        $('.user-account-popover').addClass('show');
        $(this).attr('aria-expanded', 'true');
    });

    $('.header-nav-icons .search').on('focus', function () {
        if ($('.header-nav-icons .user-account-popover').hasClass('show')) {
            $('.header-nav-icons .user-account-popover').removeClass('show');
            $('.header-nav-icons .user-account-menu .user-account-title').attr('aria-expanded', 'false');
        }
    });

    $('.header-nav-icons .wishlist a.d-flex').on('focus', function () {
        $('.header-nav-icons .minicart .popover').removeClass('show');
        if ($('.header-nav-icons .nav-item.user-account .user-account-popover').length > 0) {
            $('.header-nav-icons .nav-item.user-account .user-account-popover').removeClass('show');
        }
    });

    $('.header .header-nav-icons .nav-item.user-account').on('mouseover', function () {
        if ($(this).find('.user-account-popover').length > 0) {
            $('.header')
                .find('.fade-user-bg')
                .show();
            $(this)
                .find('.user-account-popover')
                .addClass('show');
        }
    });

    $('.header .header-nav-icons .nav-item.user-account').on('mouseleave', function () {
        $(this)
            .find('.user-account-popover')
            .removeClass('show');
        $('.header')
            .find('.fade-user-bg')
            .hide();
    });

    $(document).on('click', '.add-to-cart-messages', function (event) {
        var containerWidth = $(this).innerWidth();
        var btnWidth = containerWidth - 34;
        if (containerWidth > 1000) {
            var clickPos = event.clientX;
            if (clickPos > btnWidth) {
                $('.add-to-cart-messages').empty();
            }
        }
    });

    // handle promotion countdown in header-banner content slots
    if ($('#countdown').length > 0) {
        var dateTime = $('.countdown-datetime').html();
        if (!dateTime) {
            $('.countdown-text').remove();
            return;
        }
        var today = new Date();
        var countdownDate = new Date(dateTime);
        if (countdownDate <= today) {
            $('.countdown-text').remove();
        } else {
            setCounter(dateTime);

            var countdownClock = setInterval(function () {
                var countdown = calculateCountdown(dateTime);
                if (countdown && (countdown.dayDifference || countdown.hoursDifference || countdown.minutesDifference)) {
                    var days = countdown.dayDifference > 1 ? ' DAYS' : ' DAY';
                    var hours = countdown.hoursDifference > 1 ? ' HRS' : ' HR';
                    var mins = countdown.minutesDifference > 1 ? ' MINS' : ' MIN';
                    var countdownTime;
                    if (countdown.dayDifference === 0) {
                        if (countdown.hoursDifference > 0) {
                            countdownTime = countdown.hoursDifference + hours + ', ' + countdown.minutesDifference + mins;
                        } else {
                            countdownTime = countdown.minutesDifference + mins;
                        }
                    } else if (countdown.hoursDifference > 0) {
                        countdownTime = countdown.dayDifference + days + ', ' + countdown.hoursDifference + hours + ', ' + countdown.minutesDifference + mins;
                    } else {
                        countdownTime = countdown.dayDifference + days + ', ' + countdown.minutesDifference + mins;
                    }
                    $('.header-banner #countdown').html(countdownTime);
                } else {
                    clearInterval(countdownClock);
                    $('.countdown-text').css('display', 'none');
                }
            }, 60000);
        }
    }

    // ability to make the parent or bolded category not clickable
    $('body').on('click', 'a[data-clickable=false]', function (ev) {
        ev.preventDefault();
    });

    // Global-E Add Promo Messaging from GE to international promo carousel
	waitForElm('.ge-free-shipping-msg').then((elm) => {
        var geText = $(elm).text();
        $(".js-ge-text").text(geText);
	});
	waitForElm('.ge-country-switcher').then(() => {
        // Update Categories that should be hidden on international
        var geOperatedCountry = $('#geOperatedCountry').val();
        if (geOperatedCountry && geOperatedCountry === 'true') {
            $('[data-checkintl="true"]').addClass('d-none');
        }
	});
};
