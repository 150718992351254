'use strict';

var viewportUtil = require('../utils/viewport');
var throttle = require('../utils/throttle');

var LAZY_IMAGE_SELECTOR = ':not(.no-default-lazyload) .lazy-image';
var DATA_SRC_ATTR = 'data-src';
var DATA_SRCSET_ATTR = 'data-srcset';
var IMAGE_LOADED_CLASS = 'image-loaded';
var IMAGE_INVISIBLE_CLASS = 'invisible';

/**
 * Function to update image attributes
 * @param { JQuery } $imageEl - JQuery image element to update
 */
function updateAttributes($imageEl) {
    var src = $imageEl.attr(DATA_SRC_ATTR);
    var srcset = $imageEl.attr(DATA_SRCSET_ATTR);
    if (src) {
        $imageEl.attr('src', src);
    }
    if (srcset) {
        $imageEl.attr('srcset', srcset);
    }

    // Suppports picture tag src set
    var $parentEl = $imageEl.parent();
    if ($parentEl.prop('tagName') && $parentEl.prop('tagName').toLowerCase() === 'picture') {
        $parentEl.find('source').each(function (_i, sourceEl) {
            var $sourceEl = $(sourceEl);
            var sourceSrcset = $sourceEl.attr(DATA_SRCSET_ATTR);
            if (sourceSrcset) {
                $sourceEl.attr('srcset', sourceSrcset);
            }
        });
    }

    $imageEl.removeClass(IMAGE_INVISIBLE_CLASS);
    $imageEl.on('load', function (event) {
        $(event.target).addClass(IMAGE_LOADED_CLASS);
    });
}

/**
 *  Loads the image once it is in viewport
 *  @param { JQuery[] } $images - List of Jquery elements to lazy load
 */
function lazyLoad($images) {
    $images.each(function (_i, image) {
        var $image = $(image);
        if (viewportUtil.isInViewport($image) && !$image.hasClass(IMAGE_LOADED_CLASS) && !$image.closest('.no-default-lazyload').length) {
            updateAttributes($image);
        }
    });
}

/**
 *  Initilizes lazy load functionality
 */
function init() {
    var scope = this;
    var $images = $(LAZY_IMAGE_SELECTOR);

    lazyLoad($images);
    $(window)
        .on('scroll', function () {
            throttle(lazyLoad.call(scope, $images), 250);
        })
        .on('resize', function () {
            throttle(lazyLoad.call(scope, $images), 250);
        });

    $('body').on('search:updateProducts', function () {
        $(LAZY_IMAGE_SELECTOR).each(function (_i, imageEl) {
            updateAttributes($(imageEl));
        });
    });

    $('.carousel').on('slide.bs.carousel', function (event) {
        var $target = $(event.relatedTarget).find('.lazy-image');
        if ($target) {
            if ($target.attr('data-src') && !$target.hasClass(IMAGE_LOADED_CLASS)) {
                updateAttributes($target);
            }
        }
    });

    $('.main-menu .nav-item.dropdown').on('mouseenter click', function () {
        var $target = $(this);
        $('.lazy-load-nav', $target).each(function () {
            var $eachEl = $(this);
            if ($target.hasClass('show') && $eachEl.attr('data-src') && !$eachEl.hasClass(IMAGE_LOADED_CLASS)) {
                updateAttributes($eachEl);
            }
        });
    });

    $(document).on('show.bs.modal', '#footer-email-popup', function () {
        var $target = $(this);
        $(LAZY_IMAGE_SELECTOR, $target).each(function () {
            var $eachEl = $(this);
            if ($eachEl.attr('data-src') && !$eachEl.hasClass(IMAGE_LOADED_CLASS)) {
                updateAttributes($eachEl);
            }
        });
    });

    if ($('.product-pdp.product-detail').length > 0 || $('.container.cart-recommendation').length > 0 || $('.search-results').length > 0) {
        var count = 0;
        var recommendations = $('.pdp-einstein-recommendations').length;
        var recommendationInterval = setInterval(function () {
            count++;
            if ($('.pdp-einstein-recommendations .tile-image').length > 0) {
                if (recommendations === $('.pdp-einstein-recommendations').length) {
                    clearInterval(recommendationInterval);
                }
                recommendations = $('.pdp-einstein-recommendations').length;
                $('.pdp-einstein-recommendations .lazy-image').each(function () {
                    var $eachEl = $(this);
                    if ($eachEl.attr('data-src') && !$eachEl.hasClass(IMAGE_LOADED_CLASS)) {
                        updateAttributes($eachEl);
                    }
                });
            }
            if (count === 10) {
                clearInterval(recommendationInterval);
                $("body").trigger('refresh:productTile:carousel');
            }
        }, 1000);
    }
}

module.exports = {
    init: init,
    methods: {
        lazyLoad: lazyLoad
    }
};
