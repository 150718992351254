'use strict';

var Cleave = require('cleave.js').default;

module.exports = function() {
    var cleaveCommonConfig = {
        numeral: true,
        numeralThousandsGroupStyle: 'none',
        numeralDecimalScale: 0,
        numeralPositiveOnly: true,
        stripLeadingZeroes: false,
        numeralIntegerScale: 5
    };

    if ($('.login-page form.trackorder').length > 0) {
        cleaveCommonConfig.numeralIntegerScale = 10;
    }

    if ($('.ZipCode').length > 0 && $('.ge-country-switcher .flag-icon-us').length !== 0) {
        new Cleave('.ZipCode', cleaveCommonConfig);
    }
};
