'use strict';
let customUtil = require('../customUtil');

module.exports = {
    setCarousel: function($el, slickConfigs) {
        if (customUtil.isEmpty($el) && Object.keys(slickConfigs).length === 0) return;
        $el.not('.slick-initialized').slick(slickConfigs);
    },
    destroyCarousel: function($el) {
        if($el.hasClass('slick-initialized')) {
            $el[0].slick.unslick();
        }
    },
    reinitCarousel: function($el, slickConfigs) {
        this.destroyCarousel($el);
        this.setCarousel($el, slickConfigs);
    }
}


